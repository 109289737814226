import React from 'react'

const IconToggle = ({
  width = 16,
  height = 16,
  color = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg 
      width="8" 
      height="34" 
      viewBox="0 0 8 34" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      className="toggleIcon"
      {...svgAttributes}
    >
      <circle cx="4" cy="4" r="4" fill={color}/>
      <circle cx="4" cy="17" r="4" fill={color}/>
      <circle cx="4" cy="30" r="4" fill={color}/>
    </svg>

  )
}

export default IconToggle
