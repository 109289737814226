module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-5SMNSHG","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"hotjar":{"hjid":"3052424","hjsv":"6","cookieName":"gatsby-gdpr-hotjar"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":false,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"injectPageProps":false,"layout":"/opt/build/repo/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BraveBison","short_name":"BraveBison","description":"A different beast: we're a media, marketing and technology company built for the new era.","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"static/images/favicon.png","icons":[{"src":"static/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"static/images/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"static/images/icons/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"static/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"static/images/icons/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"static/images/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/images/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"static/images/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b6a31d48d97c95d640e86a2e68331144"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5SMNSHG","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com/","routeChangeEventName":"gatsby-route-change","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
