import React from 'react'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

const IconCross = ({
  width = 20,
  height = 20,
  fill = 'currentColor',
  responsive = true,
  open = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="closeIcon"
      {...svgAttributes}
    >
      <path
        stroke={fill}
        style={{
          transform: `rotate(${open ? 45 : 0}deg)`,
          transformOrigin: '50% 50%',
          transition: `transform 0.15s ${cubicBezier.inOut}`,
        }}
        strokeWidth="1.5"
        d="M10 0v20m10-10H0"
      />
    </svg>
  )
}

export default IconCross
