import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { AnimateSlideInContent } from './index.style'
import { useStore } from '@Store/'
import { animation } from '@styles/vars/animation.style'
import { useEffect } from 'react'
import { useState } from 'react'

const AnimateSlideIn = ({
  enterFrom = 'bottom',
  duration = 0.4,
  delay = 0,
  triggerOnce = true,
  offset = '3.2rem',
  animate = true,
  rootMargin = animation.rootMargin,
  children,
}) => {
  const [ref, inView] = useInView({
    triggerOnce,
    rootMargin: rootMargin,
  })
  const [store] = useStore()
  const [reveal, setReveal] = useState(false)
  const { showPageMask } = store

  useEffect(() => {
    if (!showPageMask) {
      setReveal(true)
    }
  }, [showPageMask])

  return (
    <AnimateSlideInContent
      ref={ref}
      inView={inView && animate && reveal}
      enterFrom={enterFrom}
      offset={offset}
      duration={duration}
      delay={delay}
    >
      {children}
    </AnimateSlideInContent>
  )
}

AnimateSlideIn.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  offset: PropTypes.string,
  rootMargin: PropTypes.string,
  enterFrom: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
}

export default AnimateSlideIn
