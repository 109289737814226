import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollSmoother } from '@utils/gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useStore } from '@Store/'
import { setSmoothScroll } from '@Store/'
import { setHideHeader, setOnBannerPosition } from '@Store/'
import { setScrollDirection } from '@Store/'
import { isIOS, isDesktop } from 'react-device-detect'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

const SmoothScroll = ({ callbacks }) => {
  const [store, dispatch] = useStore()
  const { smoothScroll, scrollDirection, hideHeader, showHeaderBackground } =
    store
  const smoothScrollRef = useRef()
  const scrollDirectionRef = useRef()
  const showHeaderRef = useRef()
  const showHeaderBackgroundRef = useRef()
  const showHeaderThreshold = 0.09
  const showHeaderBackgroundThreshold = 0.02
  let scroll;

  useEffect(() => {
    smoothScrollRef.current = smoothScroll
  }, [smoothScroll])

  useEffect(() => {
    scrollDirectionRef.current = scrollDirection
  }, [scrollDirection])

  useEffect(() => {
    showHeaderRef.current = hideHeader
  }, [hideHeader])

  useEffect(() => {
    showHeaderBackgroundRef.current = showHeaderBackground
  }, [showHeaderBackground])

  useEffect(() => {
    setHideHeader(dispatch, false)

    scroll = ScrollSmoother.create({
      smooth: 1.3,
      smoothTouch: false,
      effects: false,
      normalizeScroll: false,
      ignoreMobileResize: true,
      onUpdate: self => {
        if (!scroll) return

        if (scroll.progress > showHeaderThreshold && !showHeaderRef.current) {
          setHideHeader(dispatch, true)
        } else if (
          scroll.progress <= showHeaderThreshold &&
          showHeaderRef.current
        ) {
          setHideHeader(dispatch, false)
        }

        if (
          scroll.progress > showHeaderBackgroundThreshold &&
          !showHeaderBackgroundRef.current
        ) {
          setOnBannerPosition(dispatch, true)
        } else if (
          scroll.progress <= showHeaderBackgroundThreshold &&
          showHeaderBackgroundRef.current
        ) {
          setOnBannerPosition(dispatch, false)
        }

        if (scrollDirectionRef.current !== self.scrollTrigger?.direction) {
          setScrollDirection(dispatch, self.scrollTrigger?.direction)
        }
      },
    })

    window.addEventListener('startLoading', (e) => {
      if(window.pathName !== window.location.pathname){
        if(isDesktop) {
          scroll.scrollTo(1, false);
        }
        window.pathName = window.location.pathname
      }
    })

    setSmoothScroll(dispatch, scroll)

    return () => {
      if (smoothScrollRef.current) {
        smoothScrollRef.current.kill()
      }

      ScrollTrigger.clearMatchMedia()
    }
  }, [callbacks.pathname, dispatch])

  useEffect(() => {
    ScrollTrigger.refresh()
  }, [store.resized])

  useEffect(() => {
  }, [])

  return null
}

export default SmoothScroll
