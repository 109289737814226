import { css } from 'styled-components'
import { font } from '@styles/vars/font.style'
import { colors } from '@styles/vars/colors.style'
import { transparentize } from 'polished'

export const typography = css`
  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  body {
    font-size: 1.8rem;
    font-family: ${font.sans.family}, ${font.fallback.family};
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: ${colors.charcoal};
  }

  /* Links */
  a,
  button {
    color: currentColor;
  }

  a {
    display: inline-flex;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  /* Forms */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  button {
    cursor: pointer;

    :active {
      color: currentColor;
    }
  }

  /* Selection */
  ::-moz-selection {
    background: ${colors.charcoal};
    color: ${colors.white};
    text-shadow: none;
  }

  ::selection {
    background: ${colors.sky};
    color: ${colors.white};
    text-shadow: none;
  }

  /* Focus */
  *:focus {
    outline: ${transparentize(0, colors.sky)} auto 0.4rem;
    outline-style: solid;

    &:not(:focus-visible) {
      outline: none;
    }
  }
`
