import { colors } from '@styles/vars/colors.style'
import gsap from 'gsap'
import { css } from 'styled-components'

export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export function scrollTo(smoothScroll, target, options = {}) {
  gsap.to(smoothScroll, {
    scrollTop: smoothScroll.offset(target),
    duration: 0.8,
    ease: 'inOut',
    ...options,
  })
}

export const roundedCorners = css`
  overflow: hidden;
  border-radius: 0.5rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export function getNamedColor(name) {
  switch (name) {
    case 'Charcoal':
      return colors.charcoal
    case 'Electric Red':
      return colors.red
    case 'Bright Orange':
      return colors.brightOrange
    case 'Bright Yellow':
      return colors.brightYellow
    case 'White':
      return colors.white
    case 'Pink':
      return colors.pink
    case 'Pastel Orange':
      return colors.pastelOrange
    case 'Pastel Yellow':
      return colors.pastelYellow
    case 'Slate':
      return colors.slate
    case 'Green':
      return colors.green
    case 'Sky Blue':
      return colors.sky
    case 'Ocean Blue':
      return colors.ocean
    case 'Misty Grey':
      return colors.mist
    case 'Pastel Green':
      return colors.pastelGreen
    case 'Pastel Sky':
      return colors.pastelSky
    case 'Pastel Ocean':
      return colors.pastelOcean

    default:
      break
  }
}

export function getNamedTheme(name) {
  switch (name) {
    case 'Charcoal Pastel Green':
      return {background: colors.pastelGreen, color: colors.charcoal}
    case 'Charcoal Pastel Sky':
      return {background: colors.pastelSky, color: colors.charcoal}
    case 'Charcoal Orange':
      return {background: colors.brightOrange, color: colors.charcoal}
    case 'Charcoal Pastel Yellow + Electric Red':
      return {background: colors.red, color: colors.pastelYellow}
    case 'Charcoal Pastel Orange + Green':
      return {background: colors.pastelOrange, color: colors.green}
    case 'Charcoal Pink':
      return {background: colors.pink, color: colors.charcoal}
    default:
      break
  }
}
