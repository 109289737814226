import React, { useEffect } from 'react'
import { hideMobileNav, showMobileNav, useStore } from '@Store'
import { disablePageScroll, enablePageScroll } from '@utils'
import {
  MenuToggleMain,
  MenuToggleWrapper,
  MenuToggleDot,
  MenuToggleDotInner,
} from './index.style'
import { colors } from '@styles/vars/colors.style'
import IconCross from '@svgs/IconCross'
import IconToggle from '@svgs/IconToggle'

const MenuToggle = () => {
  const [store, dispatch] = useStore()
  const { mobileNavIsOpen, headerColor, showHeaderBackground, hideHeader } = store

  useEffect(() => {
    if (mobileNavIsOpen) {
      disablePageScroll()
    } else {
      enablePageScroll()
    }
  }, [mobileNavIsOpen])

  const handleClick = () => {
    if (mobileNavIsOpen) {
      hideMobileNav(dispatch)
    } else {
      showMobileNav(dispatch)
    }
  }

  const dotColor = mobileNavIsOpen ? colors.white : headerColor

  return (
    <MenuToggleWrapper>
      <MenuToggleMain onClick={handleClick} aria-label="Toggle menu">
        <MenuToggleDot open={mobileNavIsOpen} color={dotColor}>
          <MenuToggleDotInner open={mobileNavIsOpen}>
            <IconCross
              fill={colors.white}
              responsive={false}
              width={10}
              height={10}
              className="close"
            />
            <IconToggle 
              color={hideHeader ? headerColor : showHeaderBackground ? headerColor : colors.white}
              responsive={false}
              width={8}
              height={34}
              className="toggle"
            />
          </MenuToggleDotInner>
        </MenuToggleDot>
      </MenuToggleMain>
    </MenuToggleWrapper>
  )
}

export default MenuToggle
