import React from 'react'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

const IconCross = ({
  width = 20,
  height = 20,
  fill = 'currentColor',
  responsive = true,
  open = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg 
      width="48" 
      height="36" 
      viewBox="0 0 48 36" 
      fill="none" 
      {...svgAttributes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        id="Vector" 
        d="M27.6 10.4508H19.2V1.78393C19.2 1.28869 18.918 0.842962 18.48 0.651053C18.048 0.452954 17.538 0.54581 17.184 0.880103L0.384009 16.9757C0.138009 17.2109 0 17.539 0 17.8795C0 18.22 0.138009 18.5543 0.384009 18.7833L17.184 34.8789C17.532 35.2132 18.042 35.306 18.48 35.1079C18.918 34.9098 19.2 34.4641 19.2 33.975V25.3082H25.2C35.268 25.3082 41.622 28.193 45.804 34.6622C46.032 35.0151 46.41 35.2132 46.8 35.2132C46.914 35.2132 47.034 35.1946 47.148 35.1575C47.652 35.0027 48 34.5198 48 33.975C48 23.1229 42.66 10.4508 27.6 10.4508Z" 
        fill={fill}
      />
    </svg>
  )
}

export default IconCross
