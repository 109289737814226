import React, { useEffect, useState } from 'react';
import {
    DisplayButton,
    DisplayLabel,
    BackArea,
    DisplayControls
} from './index.style'
import { useStore } from '@Store/'
import { toggleInsightsDisplayList } from '@Store/'
import PropTypes from 'prop-types';

const Display = ({mobile}) => {
    const [store, dispatch] = useStore()
    const [show, setShow] = useState(false);
    const {
        insightsDisplayList
    } = store

    const changeDisplay = () => {
        toggleInsightsDisplayList(dispatch, !insightsDisplayList)
    }

    useEffect(() => {
        const _elemParent = document.getElementById('displayParent');
        window.addEventListener('scroll', () => {
            const scrollTop = window.scrollY || window.pageYOffset;
            const parentHeight = _elemParent?.offsetHeight || 0;
            if(scrollTop > 300 && scrollTop < parentHeight) {
                setShow(true);
            }else {
                setShow(false);
            }
        });

        return () => {
            window.removeEventListener('scroll', () => {});
        };
    }, []);
    return (
        <DisplayControls id="displayControls" mobile={mobile} show={show}>
            <DisplayButton type="button" onClick={changeDisplay} className={!insightsDisplayList ? 'active' : null}>
                <BackArea className='backArea'></BackArea>
                <DisplayLabel className='list'>List</DisplayLabel>
                <DisplayLabel className='grid'>Grid</DisplayLabel>
            </DisplayButton>
        </DisplayControls>
    );
};

Display.propTypes = {
    activeState: PropTypes.bool,
    setActiveState: PropTypes.func,
};

export default Display;