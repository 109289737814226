import { colors } from '@styles/vars/colors.style'
import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'

export const DisplayControls = styled.div`
    
    button {
        text-transform: capitalize;
        position: relative;
        &:disabled {
            z-index: 1;
        }
        &:last-child {
            margin-left: -35px;
            > div {
                padding-left: 50px !important;
            }
        }
        > div {
            padding: 12px 24px !important;
        }
    }
    ${mq.tabletL} {
        display: ${props => props.mobile ? 'none' : ''};
    }
    ${mq.tabletLMax} {
        display: ${props => props.mobile ? '' : 'none'};
        opacity: ${props => props.show ? '1' : '0'};
        position: fixed;
        bottom: 20px;
        left: 0;
        width: 100%;
        z-index: 4;
        transition: all 0.25s ease;
        > span {
            width: 140px;
            margin: auto;
        }
    }
`

export const DisplayButton = styled.span`
    display: flex;
    position: relative;
    border: 1px solid ${colors.charcoal};
    border-radius: 7rem;
    cursor: pointer;
    background: ${colors.white};
    &.active {
        .backArea {
            left: 65px;
        }
        .grid {
            color: ${colors.white}
        }
        .list {
            color: ${colors.charcoal}
        }
    }
`

export const BackArea = styled.div`
    display: flex;
    position: absolute;
    height: 100%;
    left: 0;
    border-radius: 7rem;
    width: 76px;
    background: ${colors.charcoal};
    transition: all 0.25s ease;
`

export const DisplayLabel = styled.div`
    padding: 7px 0px;
    width: 70px;
    text-align: center;
    position: relative;
    transition: all 0.25s ease;
    &.grid {
        color: ${colors.charcoal}
    }
    &.list {
        color: ${colors.white}
    }
`
