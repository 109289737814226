import { breakpoints } from './breakpoints.style'

const mq = {}

for (const key of Object.keys(breakpoints)) {
  mq[key] = `@media (min-width: ${breakpoints[key] / 16}rem)`
  mq[`${key}Max`] = `@media (max-width: ${(breakpoints[key] - 1) / 16}rem)`
}

export { mq }
