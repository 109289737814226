import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'

const paddingMin = 20
const paddingMax = 60

export const matchContainerPadding = (property, invert = false) => {
  const min = invert ? -paddingMin : paddingMin
  const max = invert ? -paddingMax : paddingMax

  return `
    ${clamp(property, min, max)}
  `
}


export const ContainerMain = styled.div`
  width: 100%;
  max-width: ${props =>
    props.fullWidth
      ? undefined
      : `${(paddingMax * 2 + breakpoints.contained) / 10}rem`};
  margin: 0 auto;
  ${matchContainerPadding('padding-left')}
  ${matchContainerPadding('padding-right')}
  overflow: ${props => (props.allowOverflow ? undefined : 'hidden')};
`
