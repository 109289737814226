import styled from 'styled-components'
import { setVh } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { zIndex } from '@styles/vars/zIndex.style'

export const TransitionMaskWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  z-index: ${zIndex.pageTransitionMask};
`

export const TransitionMaskBlock = styled.div`
  position: relative;
  transform-origin: 50% 0%;
  width: 100%;
  ${setVh('height', 100)}
  z-index: 1;
  background-color: ${colors.charcoal};
`
