import React from 'react'

const IconTick = ({
  width = 28,
  height = 28,
  color = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path stroke={color} d="m7 13.76 5.303 5.304 9.196-12.325" />
    </svg>
  )
}

export default IconTick
