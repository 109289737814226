import { AnimateSlideInContent } from '@components/animation/AnimateSlideIn/index.style'
import { mq } from '@styles/vars/media-queries.style'
import { getStyles, textStylesConfig } from '@styles/vars/textStyles.style'
import styled from 'styled-components'


export const SubscribeMain = styled.div`
  ${AnimateSlideInContent} {
    width: 100%;
  }
`

export const SubscribeFormWrapper = styled.div`
  width: 100%;
`

export const SubscribeFormInner = styled.div`
  pointer-events: ${props => (props.submitted ? `none` : `all`)};
  position: relative;
  width: 100%;

  ${mq.tabletL} {
    max-width: 51rem;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
  }
`

export const SubscribeLabel = styled.label`
  position: absolute;
  top: calc(100% + 10px);
  font-size: 14px;
`

export const SubscribeInput = styled.input`
  width: 100%;
  padding-top: 1rem;
  padding-right: 4.4rem;
  padding-bottom: 1rem;
  ${getStyles(textStylesConfig.label)}
  color: currentColor;
  border: none;
  border-bottom: 0.15rem solid;
  background-color: transparent;

  &:placeholder {
    color: currentColor;
    opacity: 0.5;
  }
  &:focus-visible {
    outline: unset;
  }
`
export const SubscribeSubmit = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  bottom: 0;
  width: 4.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  background-color: transparent;
  border: none;
`

export const Message = styled.input`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  color: red;
  white-space: nowrap;
`