import React, { forwardRef } from 'react'
import {
  SuperSerif,
  SuperSans,
  Heading1Serif,
  Heading1Sans,
  Heading2Serif,
  Heading2Sans,
  Heading3Serif,
  Heading3Sans,
  Heading4Serif,
  Heading4Sans,
  TextBody,
  TextBody2,
  Caption,
  Label,
} from '@styles/vars/textStyles.style'

const ThisSuperSerif = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <SuperSerif ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </SuperSerif>
  )
)

const ThisSuperSans = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <SuperSans ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </SuperSans>
  )
)

const ThisHeading1Serif = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading1Serif
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Heading1Serif>
  )
)

const ThisHeading1Sans = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading1Sans
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Heading1Sans>
  )
)

const ThisHeading2Serif = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading2Serif
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Heading2Serif>
  )
)

const ThisHeading2Sans = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading2Sans
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Heading2Sans>
  )
)

const ThisHeading3Serif = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <>
      {(as === 'p') ?
        <SuperSerif
          ref={ref}
          as={as}
          color={color}
          maxWidth={maxWidth}
          {...props}
        >
          {children}
        </SuperSerif>
        :
        <Heading3Serif
          ref={ref}
          as={as}
          color={color}
          maxWidth={maxWidth}
          {...props}
        >
          {children}
        </Heading3Serif>
      }
    </>
  )
)

const ThisHeading3Sans = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <>
      {(as === 'p') ?
        <SuperSerif
          ref={ref}
          as={as}
          color={color}
          maxWidth={maxWidth}
          {...props}
        >
          {children}
        </SuperSerif>
        :
        <Heading3Sans
          ref={ref}
          as={as}
          color={color}
          maxWidth={maxWidth}
          {...props}
        >
          {children}
        </Heading3Sans>
      }
    </>
  )
)

const ThisHeading4Serif = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading4Serif
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Heading4Serif>
  )
)

const ThisHeading4Sans = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading4Sans
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Heading4Sans>
  )
)

const ThisTextBody = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBody ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TextBody>
  )
)

const ThisTextBody2 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBody2 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TextBody2>
  )
)

const ThisCaption = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Caption ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Caption>
  )
)

const ThisLabel = forwardRef(
  ({ children, as, color, maxWidth, uppercase = true, ...props }, ref) => (
    <Label
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      uppercase={uppercase}
      {...props}
    >
      {children}
    </Label>
  )
)

export {
  ThisSuperSerif as SuperSerif,
  ThisSuperSans as SuperSans,
  ThisHeading1Serif as Heading1Serif,
  ThisHeading1Sans as Heading1Sans,
  ThisHeading2Serif as Heading2Serif,
  ThisHeading2Sans as Heading2Sans,
  ThisHeading3Serif as Heading3Serif,
  ThisHeading3Sans as Heading3Sans,
  ThisHeading4Serif as Heading4Serif,
  ThisHeading4Sans as Heading4Sans,
  ThisTextBody as TextBody,
  ThisTextBody2 as TextBody2,
  ThisCaption as Caption,
  ThisLabel as Label,
}