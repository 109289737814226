import React from 'react'

const IconArrowDown = ({
  width = 20,
  height = 12,
  color = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 12"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        stroke={color}
        strokeWidth="1.5"
        d="M18.547 1.485 9.47 10.577 1.001 2.09"
      />
    </svg>
  )
}

export default IconArrowDown
