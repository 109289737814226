import styled from 'styled-components'
import { font } from '@styles/vars/font.style'
import { clamp } from '@styles/utils/conversion.style'

export const textStylesConfig = {
  superSerif: {
    family: font.serif.family,
    weight: font.serif.weight.light,
    size: {
      min: 42,
      max: 128,
    },
    lineHeight: 1.2,
    letterSpacing: -0.02,
  },
  superSans: {
    family: font.sans.family,
    weight: font.sans.weight.extrabold,
    size: {
      min: 42,
      max: 128,
    },
    lineHeight: 1.2,
    letterSpacing: -0.04,
  },
  heading1Serif: {
    family: font.serif.family,
    weight: font.serif.weight.light,
    size: {
      min: 42,
      max: 92,
    },
    lineHeight: 1.2,
    letterSpacing: -0.02,
  },
  heading1Sans: {
    family: font.sans.family,
    weight: font.sans.weight.extrabold,
    size: {
      min: 42,
      max: 92,
    },
    lineHeight: 1.2,
    letterSpacing: -0.04,
  },
  heading2Serif: {
    family: font.serif.family,
    weight: font.serif.weight.light,
    size: {
      min: 32,
      max: 64,
    },
    lineHeight: 1.2,
    letterSpacing: -0.02,
  },
  heading2Sans: {
    family: font.sans.family,
    weight: font.sans.weight.extrabold,
    size: {
      min: 32,
      max: 64,
    },
    lineHeight: 1.2,
    letterSpacing: -0.04,
  },
  heading3Serif: {
    family: font.serif.family,
    weight: font.serif.weight.light,
    size: {
      min: 24,
      max: 42,
    },
    lineHeight: 1.2,
    letterSpacing: -0.01,
  },
  heading3Sans: {
    family: font.sans.family,
    weight: font.sans.weight.extrabold,
    size: {
      min: 24,
      max: 42,
    },
    lineHeight: 1.2,
    letterSpacing: -0.04,
  },
  heading4Serif: {
    family: font.serif.family,
    weight: font.serif.weight.light,
    size: {
      min: 18,
      max: 32,
    },
    lineHeight: 1.2,
    letterSpacing: -0.01,
  },
  heading4Sans: {
    family: font.sans.family,
    weight: font.sans.weight.extrabold,
    size: {
      min: 18,
      max: 28,
    },
    lineHeight: 1.2,
    letterSpacing: -0.04,
    uppercase: true,
  },
  body: {
    family: font.sans.family,
    weight: font.sans.weight.regular,
    size: {
      min: 16,
      max: 28,
    },
    lineHeight: 1.5,
  },
  body2: {
    family: font.sans.family,
    weight: font.sans.weight.regular,
    size: {
      min: 14,
      max: 22,
    },
    lineHeight: 1.5,
  },
  caption: {
    family: font.sans.family,
    weight: font.sans.weight.extrabold,
    size: {
      min: 14,
      max: 18,
    },
    lineHeight: 1,
    uppercase: true,
  },
  label: {
    family: font.sans.family,
    weight: font.sans.weight.regular,
    size: {
      min: 14,
      max: 18,
    },
    lineHeight: 1,
    uppercase: true,
  },
}

export const getStyles = style => `
  max-width: ${style.maxWidth ? `${style.maxWidth}em` : ''};
  ${clamp('font-size', style.size.min, style.size.max)}
  font-family: ${style.family};
  font-weight: ${style.weight};
  line-height: ${style.lineHeight};
  letter-spacing: ${style.letterSpacing ? `${style.letterSpacing}em` : ''};
  ${style.uppercase ? 'text-transform: uppercase' : ''};
  color: ${style.color ? style.color : ''};
`

export const SuperSerif = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.superSerif,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  strong {
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight.extrabold};
  }
`

export const SuperSans = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.superSans,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading1Serif = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading1Serif,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  strong {
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight.extrabold};
  }
`

export const Heading1Sans = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading1Sans,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading2Serif = styled.h2`
  ${props =>
    getStyles({
      ...textStylesConfig.heading2Serif,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  strong {
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight.extrabold};
  }
`

export const Heading2Sans = styled.h2`
  ${props =>
    getStyles({
      ...textStylesConfig.heading2Sans,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading3Serif = styled.h3`
  ${props =>
    getStyles({
      ...textStylesConfig.heading3Serif,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  strong {
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight.extrabold};
  }
`

export const Heading3Sans = styled.h3`
  ${props =>
    getStyles({
      ...textStylesConfig.heading3Sans,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading4Serif = styled.h4`
  ${props =>
    getStyles({
      ...textStylesConfig.heading4Serif,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  strong {
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight.extrabold};
  }
`

export const Heading4Sans = styled.h4`
  ${props =>
    getStyles({
      ...textStylesConfig.heading4Sans,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const TextBody = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.body,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  strong {
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight.extrabold};
  }
`

export const TextBody2 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.body2,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  strong {
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight.extrabold};
  }
`

export const Caption = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.caption,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Label = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.label,
      maxWidth: props.maxWidth,
      color: props.color,
      uppercase: props.uppercase,
    })}
`

export const getCustomTextStyle = (min, max) => `
  ${getStyles({
    ...max,
    size: {
      min: min.size.min,
      max: max.size.max,
    },
  })}
`
