import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { showPageMask, updatePathname, useStore } from '@Store'
import { animation } from '@styles/vars/animation.style'
import { PageTransitionLinkMain } from './index.style'

const PageTransitionLink = forwardRef(
  (
    { children, to, fill = false, underline = true, onClick, ...props },
    ref
  ) => {
    const [store, dispatch] = useStore()
    const $skipToContent =
      typeof document !== 'undefined'
        ? document.getElementById('skip-to-content')
        : null

    return (
      <PageTransitionLinkMain
        ref={ref}
        to={`/${to}`}
        exit={{
          delay: animation.maskShowDuration,
          length: 0,
          zIndex: 2,
          trigger: () => {
            if ($skipToContent) $skipToContent.focus()
          },
        }}
        entry={{
          delay: 0,
          length: animation.maskHideDuration,
          zIndex: 1,
        }}
        fill={fill ? 'true' : undefined}
        underline={underline.toString()}
        {...props}
        onClick={() => {
          if (onClick) {
            onClick()
          }

          if (
            to.indexOf(store.pathname) === -1 ||
            to.length !== store.pathname.length
          ) {
            updatePathname(dispatch, to)
            showPageMask(dispatch)
          }
        }}
      >
        {children}
      </PageTransitionLinkMain>
    )
  }
)

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  underline: PropTypes.bool,
}

export default PageTransitionLink
