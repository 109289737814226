import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const FooterMain = styled.footer`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 8.4rem;
  ${mq.tabletL} {
    padding-top: 5.4rem;
  }
  z-index: ${props => (props.smoothScrollIsActive ? undefined : -1)};
  color: ${colors.white};
  background-color: ${colors.charcoal};

  a {
    text-decoration: none;
  }
  ${mq.tabletPMax} {
    svg {
      max-width: 80%;
      margin: auto;
    }
  }
`
export const MainContainer = styled.div`
  ${mq.tabletL} {
    height: 100vh;
    display: flex;
    flex-flow: column;
    padding-top: 40px;
  }
`

export const FooterTop = styled.div`
  ${mq.tabletL} {
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
  }
`

export const FooterNewsletter = styled.div`
  overflow: hidden;
`

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 1rem;
`

export const FooterColumns = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${mq.tabletLMax} {
    margin-top: 2rem;
  }
`

export const FooterColumn = styled.div`
  min-width: 16rem;

  ${mq.tabletLMax} {
    margin-top: 2rem;
  }

  ${mq.tabletL} {
    text-align: right;
  }

  ${mq.desk} {
    min-width: 20.6rem;
  }
`
