import styled from 'styled-components'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

export const AnimateSlideInContent = styled.span.attrs(props => {
  return {
    style: {
      transition: `transform ${props.duration}s ${cubicBezier.out}${
        props.delay ? ` ${props.delay}s` : ''
      }, opacity ${props.duration}s ${cubicBezier.out}${
        props.delay ? ` ${props.delay}s` : ''
      }`,
    },
  }
})`
  display: inline-flex;
  flex-direction: column;
  opacity: ${props => (props.inView ? 1 : 0)};
  transform: ${({ enterFrom, inView, offset }) => {
    let translate

    switch (enterFrom) {
      case 'top':
        translate = `0, -${offset}`
        break

      case 'right':
        translate = `${offset}, 0%`
        break

      case 'bottom':
        translate = `0%, ${offset}`
        break

      case 'left':
        translate = `-${offset}, 0%`
        break

      default:
        break
    }

    return `translate(${inView ? '0, 0' : translate})`
  }};
`
