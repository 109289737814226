import gsap from 'gsap'
import { CustomEase } from 'gsap/all'

gsap.registerPlugin(CustomEase)

export const easings = {
  in: '0.78, 0.00, 0.91, 0.57',
  out: '0.22, 0.55, 0.37, 1.00',
  inOutOld: '1.00, 0.00, 0.67, 1.00',
  inOutShort: '0.82, 0.28, 0.52, 1.00',
  inOut: '1.00, 0.00, 0.14, 1.02',
}

export const cubicBezier = {
  in: `cubic-bezier(${easings.in})`,
  out: `cubic-bezier(${easings.out})`,
  inOutOld: `cubic-bezier(${easings.inOutOld})`,
  inOutShort: `cubic-bezier(${easings.inOutShort})`,
  inOut: `cubic-bezier(${easings.inOut})`,
}

CustomEase.create('inOutOld', easings.inOutOld)
CustomEase.create('inOutShort', easings.inOutShort)
CustomEase.create('inOut', easings.inOut)
CustomEase.create('in', easings.in)
CustomEase.create('out', easings.out)
