export const colors = {
  charcoal: '#1E1F27',
  white: '#F6F6F6',
  slate: '#304247',
  mist: '#D4D5E1',
  red: '#FF4F17',
  pink: '#FFC5D7',
  brightOrange: '#FF9516',
  pastelOrange: '#FFD099',
  brightYellow: '#FFCE20',
  pastelYellow: '#FCEEAC',
  green: '#2D4E38',
  pastelGreen: '#C5FFC5',
  sky: '#4BA8FE',
  pastelSky: '#C2E9FF',
  ocean: '#08348A',
  pastelOcean: '#A1BCFF',
}
