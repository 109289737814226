import styled from 'styled-components'
import { zIndex } from '@styles/vars/zIndex.style'
import { mq } from '@styles/vars/media-queries.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { colors } from '@styles/vars/colors.style'

export const MenuToggleWrapper = styled.div`
  position: relative;
  z-index: ${zIndex.mobileNavButtons};
`

export const MenuToggleMain = styled.button`
  position: relative;
  width: 3.2rem;
  height: 3.2rem;
  background-color: transparent;
  border: none;

  ${mq.deskNav} {
    display: none;
  }
`

export const MenuToggleDot = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform-origin: 100% 50%;
  transform: translateY(-50%) scale(${props => (props.open ? 1.75 : 1)});
  width: 38px;
  height: 38px;
`

export const MenuToggleDotInner = styled.div`
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  .closeIcon {
    display: ${props => (props.open ? 'block' : 'none')};
  }
  .toggleIcon {
    display: ${props => (props.open ? 'none' : 'block')};
  }
`
