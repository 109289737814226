import { PageTransitionLinkMain } from '@components/PageTransitionLink/index.style'
import { mq } from '@styles/vars/media-queries.style'
import { zIndex } from '@styles/vars/zIndex.style'
import styled from 'styled-components'

export const HeaderLogoMain = styled.section`
  position: relative;
  width: 25.5rem;
  z-index: ${zIndex.mobileNavButtons};
  opacity: 1;

  ${mq.deskNav} {
    width: 21.9rem;
  }

  ${mq.deskMax} {
    width: 150px;
  }

  ${PageTransitionLinkMain} {
    display: block;
    width: 100%;
    opacity: 1;
    pointer-events: ${props => (props.show ? undefined : 'none')};
  }
`
