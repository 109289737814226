import React from 'react'
import PropTypes from 'prop-types'
import PageTransitionLink from '@components/PageTransitionLink'

const CMSLink = ({ link, animate = true, ...props }) => {
  return (
    <>
      {link.page && (
        <PageTransitionLink
          to={link.page.slug ? link.page.slug : ''}
          {...props}
        >
          {link.text}
        </PageTransitionLink>
      )}

      {link.url && (
        <a href={link.url} target="_blank" rel="noreferrer" {...props}>
          {link.text}
        </a>
      )}
    </>
  )
}

CMSLink.propTypes = {
  animate: PropTypes.bool,
  link: PropTypes.object,
}

export default CMSLink
