import { colors } from '@styles/vars/colors.style'
import React, { useReducer, createContext, useContext } from 'react'

export const StoreContext = createContext()
StoreContext.displayName = 'StoreContext'

const initialState = {
  showPageMask: true,
  mobileNavIsOpen: false,
  fontIsLoaded: false,
  pathname:
    typeof window === 'undefined'
      ? null
      : window.location.pathname.charAt(0) === '/'
      ? window.location.pathname.substring(1)
      : window.location.pathname,
  popstate: 0,
  smoothScroll: null,
  scrollDirection: null,
  hideHeader: false,
  showHeaderBackground: false,
  onHideHeaderColor: null,
  onBannerPosition: false,
  headerColor: colors.white,
  headerBackground: 'transparent',
  mousePosition: [0, 0],
  resized: 0,
  caseStudyParentSlug: false,
  cookieBarIsOpen: true,
  showDisplayControls: false,
  displayList: true,
  insightsDisplayList: true,
}

function storeReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        pathname: action.pathname,
      }
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      }
    }

    case 'SHOW_PAGE_MASK': {
      return {
        ...state,
        showPageMask: true,
      }
    }

    case 'SHOW_DISPLAY_CONTROLS': {
      return {
        ...state,
        showDisplayControls: action.show,
      }
    }

    case 'TOGGLE_DISPLAY_LIST': {
      return {
        ...state,
        displayList: action.show,
      }
    }

    case 'TOGGLE_INSIGHTS_DISPLAY_LIST': {
      return {
        ...state,
        insightsDisplayList: action.show,
      }
    }

    case 'SET_ON_BANNER_POSITION': {
      return {
        ...state,
        onBannerPosition: action.show,
      }
    }

    case 'HIDE_PAGE_MASK': {
      return {
        ...state,
        showPageMask: false,
      }
    }

    case 'LOAD_FONTS': {
      return {
        ...state,
        fontIsLoaded: true,
      }
    }

    case 'SHOW_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: true,
      }
    }
    case 'HIDE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: false,
      }
    }

    case 'SET_HEADER_COLOR': {
      return {
        ...state,
        headerColor: action.color,
        headerBackground: action.background,
      }
    }

    case 'SET_ON_HIDE_HEADER_COLOR': {
      return {
        ...state,
        onHideHeaderColor: action.color,
      }
    }

    case 'SET_SMOOTH_SCROLL': {
      return {
        ...state,
        smoothScroll: action.scroll,
      }
    }

    case 'SET_SCROLL_DIRECTION': {
      return {
        ...state,
        scrollDirection: action.direction,
      }
    }

    case 'SET_HIDE_HEADER': {
      return {
        ...state,
        hideHeader: action.show,
      }
    }

    case 'SET_SHOW_HEADER_BACKGROUND': {
      return {
        ...state,
        showHeaderBackground: action.show,
      }
    }

    case 'SET_MOUSE_POSITION': {
      return {
        ...state,
        mousePosition: action.mousePosition,
      }
    }

    case 'UPDATE_RESIZED': {
      return {
        ...state,
        resized: state.resized + 1,
      }
    }

    case 'SET_IS_CASE_STUDY_PAGE': {
      return {
        ...state,
        caseStudyParentSlug: action.open,
      }
    }

    case 'CLOSE_COOKIE_BAR': {
      return {
        ...state,
        cookieBarIsOpen: false,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

export const updatePathname = (dispatch, pathname) =>
  dispatch({ type: 'UPDATE_PATHNAME', pathname })
export const updatePopState = dispatch => dispatch({ type: 'UPDATE_POP_STATE' })
export const showPageMask = dispatch => dispatch({ type: 'SHOW_PAGE_MASK' })
export const hidePageMask = dispatch => dispatch({ type: 'HIDE_PAGE_MASK' })
export const loadFonts = dispatch => dispatch({ type: 'LOAD_FONTS' })
export const showMobileNav = dispatch => dispatch({ type: 'SHOW_MOBILE_NAV' })
export const hideMobileNav = dispatch => dispatch({ type: 'HIDE_MOBILE_NAV' })
export const updateResized = dispatch => dispatch({ type: 'UPDATE_RESIZED' })
export const closeCookieBar = dispatch => dispatch({ type: 'CLOSE_COOKIE_BAR' })
export const showDisplayControls = (dispatch, show) => dispatch({ type: 'SHOW_DISPLAY_CONTROLS', show })
export const toggleDisplayList = (dispatch, show) => dispatch({ type: 'TOGGLE_DISPLAY_LIST', show })
export const toggleInsightsDisplayList = (dispatch, show) => dispatch({ type: 'TOGGLE_INSIGHTS_DISPLAY_LIST', show })
export const setHeaderColor = (dispatch, color, background) =>
  dispatch({ type: 'SET_HEADER_COLOR', color, background })
export const setOnHideHeaderColor = (dispatch, color) =>
  dispatch({ type: 'SET_HEADER_COLOR', color })
export const setOnBannerPosition = (dispatch, show) =>
  dispatch({ type: 'SET_ON_BANNER_POSITION', show })
export const setSmoothScroll = (dispatch, scroll) =>
  dispatch({ type: 'SET_SMOOTH_SCROLL', scroll })
export const setScrollDirection = (dispatch, direction) =>
  dispatch({ type: 'SET_SCROLL_DIRECTION', direction })
export const setHideHeader = (dispatch, show) =>
  dispatch({ type: 'SET_HIDE_HEADER', show })
export const setShowHeaderBackground = (dispatch, show) =>
  dispatch({ type: 'SET_SHOW_HEADER_BACKGROUND', show })
export const setMousePosition = (dispatch, mousePosition) =>
  dispatch({ type: 'SET_MOUSE_POSITION', mousePosition })
export const setCaseStudyParentSlug = (dispatch, open) =>
  dispatch({ type: 'SET_IS_CASE_STUDY_PAGE', open })

function StoreProvider(props) {
  const [store, dispatch] = useReducer(storeReducer, initialState)
  const value = [store, dispatch]

  return <StoreContext.Provider value={value} {...props} />
}

export function useStore() {
  const context = useContext(StoreContext)

  if (!context) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return context
}

function Store(props) {
  return <StoreProvider>{props.children}</StoreProvider>
}

export default Store
