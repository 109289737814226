import React from 'react'

const IconArrowRight = ({
  width = 16,
  height = 16,
  color = 'currentColor',
  responsive = true,
  ...props
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
      {...props}
    >
      <path
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
        d="M1 15 15 1m0 0H5.87M15 1v8.522"
      />
    </svg>
  )
}

export default IconArrowRight
